<template lang="pug">
include ../../../../configs/template
form.row
  div.col-12
    +select('body.additional_info.position', 'listPositionShip', 'positionOnShip', 'nameLang')
  div.col-12
    +select-validation('body.additional_info.responsibility_work_book', 'listResponsibility', 'responsibility', 'nameLang', '["required"]')
  div.col-12
    +field-validation('body.additional_info.place_work', 'workPlace', '["required"]')
  div.row.mx-1.d-flex.flex-wrap.align-center
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.date_start', 'periodStart', 'dateObjectStart', '["required", "minValue", "maxValue"]', 'readOnlyDate', '!readOnlyDate')(:readonly="readOnlyDate")
    div.col-sm-12.col-md-4
      +date-picker-validation('body.additional_info.date_end', 'periodEnd', 'dateObjectEnd', '["required", "minValue", "maxValue"]', 'readOnlyDate', '!readOnlyDate')(:readonly="readOnlyDate")
    div.col-sm-12.col-md-1.d-flex.justify-content-center.align-items-center
      label {{ $t('or') }}
    div.col-sm-12.col-md-3
      +field-validation('body.additional_info.days_work', 'totalDays', '["required"]')(:readonly="!!body.additional_info.date_start || !!body.additional_info.date_end")
  div.text-left.col-12
    +switch('body.additional_info.book_registration_practical','present','missingFemale', 'bookPractical')
</template>
<script>
import { sailor } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
const initBody = () => (
  {
    additional_info: {
      responsibility_work_book: null,
      book_registration_practical: false,
      position: null,
      place_work: null,
      date_start: null,
      date_end: null,
      days_work: null,
      status_document: STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.VERIFICATION
    }
  }
)
export default {
  props: { sailorExperienceCertificate: { type: Object, default: () => ({}) } },
  data () {
    return {
      body: initBody()
    }
  },
  validations () { return sailor.experience.employmentHistory(this) },
  computed: {
    ...mapState({
      listResponsibility: state => state.directory.responsibilityWorkBook,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listPositionShip: state => state.directory.positionsShip.filter(item => [1, 7, 48].includes(item.id))
    }),
    dateObjectStart () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateObjectEnd () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    },
    readOnlyDate () {
      return !!this.body.additional_info.days_work
    }
  },
  mounted () {
    if (Object.keys(this.sailorExperienceCertificate).length) {
      Object.keys(this.body.additional_info).forEach(key => {
        this.body.additional_info[key] = this.sailorExperienceCertificate[key]
      })
    }
  },
  methods: {
    ...mapActions(['getExperienceReferences']),
    clearBody () {
      const { params } = this.$route
      this.getExperienceReferences({ params })
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
